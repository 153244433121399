/**
 * Creates a simpler Azure user account from provided account data
 *
 * @param {string} homeAccountId
 * @param {string} name
 * @param {string} username
 * @returns {{name, homeAccountId, username}}
 */
export const userFromAccount = ({ homeAccountId, name, username }) => ({
  homeAccountId,
  name,
  username
});
