import { MsalProvider } from '@azure/msal-react';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { EventType } from '@azure/msal-browser';
import { userFromAccount } from './utils';

const INITIAL_VALUE = {
  isAuthenticated: false,
  isInitialised: false,
  account: null
};

const AzureAuthContext = createContext(INITIAL_VALUE);
const AzureLoginContext = createContext(() => {});
const AzureLogoutContext = createContext(() => {});

/**
 * Azure authentication provider component
 *
 * @param {Object} instance
 * @param {Array} scopes
 * @param {JSX.Element|Array} children
 * @returns {JSX.Element}
 * @constructor
 */
export const AzureAuthProvider = ({ instance, scopes, children }) => {
  const accounts = instance.getAllAccounts();
  const [value, setValue] = useState(INITIAL_VALUE);

  /**
   * Successfully Azure login event handler
   *
   * This handles is called for all events emitted by MSAL instance. When a
   * successful login is detected, internal state is updated.
   *
   * @param {Object} event
   */
  const msalEventCallback = (event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      instance.setActiveAccount(account);
      setValue({ ...value, account: userFromAccount(account) });
    }

    if (event.eventType === EventType.LOGIN_START) {
      window.sessionStorage.setItem('_working_', 1);
    }

    if (event.eventType === EventType.HANDLE_REDIRECT_END) {
      const working = window.sessionStorage.getItem('_working_');
      if (working) {
        window.sessionStorage.removeItem('_working_');
        setValue({ ...value, isInitialised: true });
      }
    }
  };

  /**
   * Register event handler callback
   */
  useEffect(() => {
    const callbackId = instance.addEventCallback(msalEventCallback);
    return () => instance.removeEventCallback(callbackId);
  }, []);

  /**
   * Check initial initialization and load local auth data
   */
  useEffect(() => {
    const working = window.sessionStorage.getItem('_working_');
    let newValue = { ...value, isInitialised: !working };
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      newValue = {
        ...newValue,
        isAuthenticated: true,
        account: userFromAccount(instance.getActiveAccount())
      };
    }

    setValue(newValue);
  }, []);

  /**
   * Initiates a login process
   *
   * @returns {Promise<void>}
   */
  const login = async () => {
    await instance.loginRedirect(scopes);

    setValue({
      ...value,
      isAuthenticated: true,
      account: userFromAccount(instance.getActiveAccount())
    });
  };

  /**
   * Initiates a logout process
   *
   * @returns {Promise<void>}
   */
  const logout = async () => {
    await instance.logoutRedirect(scopes);
    setValue({ ...value, isAuthenticated: true });
  };

  return (
    <MsalProvider instance={instance}>
      <AzureAuthContext.Provider value={value}>
        <AzureLoginContext.Provider value={login}>
          <AzureLogoutContext.Provider value={logout}>
            {children}
          </AzureLogoutContext.Provider>
        </AzureLoginContext.Provider>
      </AzureAuthContext.Provider>
    </MsalProvider>
  );
};

export const useAzureAuth = () => useContext(AzureAuthContext);
export const useAzureLogin = () => useContext(AzureLoginContext);
export const useAzureLogout = () => useContext(AzureLogoutContext);

export default AzureAuthProvider;
